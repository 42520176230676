import { AddItem, CreateShow, RemoveItem } from 'components/Actions'
import { PublishItems } from 'components/Actions/PublishItems'
import { UnpublishItems } from 'components/Actions/UnpublishItems'
import { IMultiImageAttachment, IWorkflow } from 'components/Behaviors'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { EditComponent } from 'components/Fields'
import {
  AccreditationPressForm,
  AccreditationProfessionalForm,
  EnsForm,
  InstrumentForm,
  PaymentForm,
  PerformanceForm,
  ProfessionalActivityForm,
  ReunioRapidaForm,
  ShopForm,
  ShowForm,
  StandForm,
  TypologyProfessionalActivityForm,
} from 'components/Forms'
import { FaqForm } from 'components/Forms/FaqForm'
import {
  AccountView,
  AccreditationView,
  CategoriaPlaImpulsMusicaView,
  EnsPremsaView,
  EnsProfessionalView,
  EnsServeisView,
  EnsView,
  InstrumentView,
  PaymentView,
  PerformanceView,
  PerformancesView,
  ProfessionalActivityView,
  ProfessionalsActivitiesView,
  PropostaView,
  ReunioRapidaView,
  ShopView,
  ShowView,
  ShowsView,
  StandView,
  TypologyProfessionalActivityView,
  YearView,
} from 'guillotina/views'
import { FranjaReunioRapidaView } from './views/franja_reunio_rapida'

const registry = {
  // to register views around guillotina objects paths
  paths: {},
  // default views for content types
  views: {
    Proposta: PropostaView,
    Year: YearView,
    Account: AccountView,
    AcreditacioProfessional: AccreditationView,
    AcreditacioPremsa: AccreditationView,
    Ens: EnsView,
    EnsProfessional: EnsProfessionalView,
    EnsPremsa: EnsPremsaView,
    EnsServeis: EnsServeisView,
    Espectacle: ShowView,
    Espectacles: ShowsView,
    Pagament: PaymentView,
    Actuacions: PerformancesView,
    Actuacio: PerformanceView,
    ActivitatProfessional: ProfessionalActivityView,
    ActivitatsProfessionals: ProfessionalsActivitiesView,
    TipologiaActivitatProfessional: TypologyProfessionalActivityView,
    Estand: StandView,
    Shop: ShopView,
    ReunioRapida: ReunioRapidaView,
    FranjaReunioRapida: FranjaReunioRapidaView,
    Instrument: InstrumentView,
    CategoriaPlaImpulsMusica: CategoriaPlaImpulsMusicaView,
  },
  // forms for adding content, they are fired
  // throught an action
  forms: {
    // ZonaGeo: ZonaForm,
    Ens: EnsForm,
    EnsProfessional: EnsForm,
    EnsPremsa: EnsForm,
    EnsServeis: EnsForm,
    Estand: StandForm,
    Actuacio: PerformanceForm,
    ReunioRapida: ReunioRapidaForm,
    Espectacle: ShowForm,
    AcreditacioProfessional: AccreditationProfessionalForm,
    AcreditacioPremsa: AccreditationPressForm,
    Parada: ShopForm,
    Pagament: PaymentForm,
    ActivitatProfessional: ProfessionalActivityForm,
    TipologiaActivitatProfessional: TypologyProfessionalActivityForm,
    Faq: FaqForm,
    Instrument: InstrumentForm,
  },
  // when using the default being able to configure properties
  properties: {
    // Producto: ProductProps,
    // Tag: TagProps
  },
  behaviors: {
    'guillotina.contrib.workflows.interfaces.IWorkflowBehavior': IWorkflow,
    'guillotina.contrib.image.behaviors.IMultiImageAttachment': IMultiImageAttachment,
  },
  components: {
    Path: Breadcrumbs,
    EditComponent: EditComponent,
  },
  actions: {
    createShowFromProposal: CreateShow,
    removeItem: RemoveItem,
    addItem: AddItem,
    publishItem: PublishItems,
    unpublishItem: UnpublishItems,
  },
}

const Cfg = {
  icons: {
    Year: 'fa fa-percent',
    Ens: 'fas fa-image',
    Proposta: 'fas fa-globe',
  },
  PageSize: 30,
  properties_default: ['@id', '@uid', 'nom'],
  properties_editable: [],
  DelayActions: 2000,
  Permissions: [
    'fm.GestionarPropostes',
    'fm.ComentarPropostes',
    'fm.ComentarFiraPropostes',
    'fm.VeureComentarisPropostes',
    'fm.AccedirERP',
    'fm.ModificarCampNeutreProposta',
    'fm.ModificarAmbitsProposta',
    'fm.AccedirDadesGeneralsFira',
  ],
}

export default {
  registry,
  Cfg,
  url: `${process.env.REACT_APP_GUILLOTINA}${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}`,
}
