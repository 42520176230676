import {
  Checkbox,
  FileUpload,
  Input,
  InputList,
  ItemModel,
  parser,
  useTraversal,
} from '@guillotinaweb/react-gmi'
import { get } from 'helpers/utils'
import React from 'react'

import { InputSearchList } from './InputSearchList'
import { RichText } from './RichText'

import { dynamicFunctionsPaymentForm } from 'helpers/payments'
import { DateStartEndField } from './DateStartEndField'
import { SelectVocabulary } from './SelectVocabulary'

export const EditComponent = React.forwardRef(
  ({ schema, val, setValue, dataTest, className, ...rest }, ref) => {
    const traversal = useTraversal()
    const model = new ItemModel(traversal.context)
    if (schema?.type === 'object' && schema.widget !== 'file') {
      const value = val
      return (
        <>
          {schema.title && <h4 className="subtitle mt-2">{schema.title}</h4>}
          {Object.keys(get(schema, 'properties', {})).map((key) => {
            const subSchema = get(schema, 'properties', {})[key]
            const requiredFields = get(schema, 'required', [])
            return (
              <EditComponent
                key={`${rest.id}[${key}]`}
                id={`${rest.id}[${key}]`}
                schema={subSchema}
                val={value && key in value ? value[key] : ''}
                placeholder={subSchema?.title ?? ''}
                required={requiredFields.includes(key)}
                setValue={(ev) => {
                  setValue({ ...value, [key]: ev })
                }}
                dataTest={`${key}TestInput`}
              />
            )
          })}
        </>
      )
    } else if (schema?.widget === 'textarea' || schema?.widget === 'richtext') {
      return <RichText val={val} setValue={setValue} {...rest} />
    } else if (schema?.type === 'boolean') {
      return (
        <Checkbox
          value={!!val}
          className={className}
          classNameInput="mr-3"
          onChange={(ev) => setValue(ev)}
          ref={ref}
          dataTest={dataTest}
          {...rest}
        />
      )
    } else if (get(schema, 'widget', null) === 'date-start-end') {
      return (
        <>
          <label className="label">{rest.placeholder}</label>
          <DateStartEndField
            value={val || []}
            className={className}
            onChange={(ev) => setValue(ev)}
            dataTest={dataTest}
            {...rest}
          />
        </>
      )
    } else if (schema?.type === 'array' && get(schema, 'items.widget', null) === 'searchable') {
      const getQueryFilters = get(schema, 'items.query_filters', {})
      let query = []
      if (Object.keys(getQueryFilters).length > 0) {
        const queryFilters = Object.keys(getQueryFilters).map((key) => {
          return parser(`${key}=${getQueryFilters[key]}`)
        })
        query = queryFilters.flat()
      } else if (get(schema, 'items.queryFn', null)) {
        query = dynamicFunctionsPaymentForm[get(schema, 'items.queryFn', null)](model.item['@uid'])
      }

      let path = null
      if (get(schema, 'items.pathFn', null)) {
        path = dynamicFunctionsPaymentForm[get(schema, 'items.pathFn', null)](
          model,
          traversal.client
        )
      }
      return (
        <>
          <label className="label">{rest.placeholder}</label>
          <InputSearchList
            path={path}
            qs={query}
            value={val || []}
            className={className}
            onChange={(ev) => setValue(ev)}
            ref={ref}
            dataTest={dataTest}
            {...rest}
          />
        </>
      )
    } else if (schema?.type === 'array' && get(schema, 'items.enum', null) === null) {
      return (
        <>
          <label className="label">{rest.placeholder}</label>
          <InputList
            value={val || []}
            className={className}
            onChange={(ev) => setValue(ev)}
            ref={ref}
            dataTest={dataTest}
            {...rest}
          />
        </>
      )
    } else if (schema?.widget === 'file') {
      return (
        <FileUpload
          onChange={(ev) => setValue(ev)}
          label={get(val, 'filename', null)}
          dataTest={dataTest}
          {...rest}
        />
      )
    } else if (
      (schema?.type === 'array' && get(schema, 'items.vocabularyName', null) !== null) ||
      get(schema, 'vocabularyName', null) !== null
    ) {
      return (
        <SelectVocabulary
          schema={schema}
          setValue={setValue}
          val={val}
          dataTest={dataTest}
          className={className}
          {...rest}
        />
      )
    } else if (schema?.widget === 'color') {
      return (
        <Input
          value={val}
          className={className}
          dataTest={dataTest}
          onChange={(ev) => setValue(ev !== '' ? ev : null)}
          ref={ref}
          type="color"
          {...rest}
        />
      )
    }

    const getInputType = () => {
      switch (schema?.type) {
        case 'integer':
          return 'number'
        case 'datetime':
          return 'datetime-local'
        default:
          return 'text'
      }
    }
    return (
      <Input
        value={val}
        className={className}
        dataTest={dataTest}
        onChange={(ev) => setValue(ev !== '' ? ev : null)}
        ref={ref}
        type={getInputType()}
        {...rest}
      />
    )
  }
)

EditComponent.displayName = 'EditComponent'
export default EditComponent
